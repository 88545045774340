/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 7, 2019 */



@font-face {
    font-family: 'telefonicabold';
    src: url('telefonicaweb-bold-webfont.eot');
    src: url('telefonicaweb-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('telefonicaweb-bold-webfont.woff2') format('woff2'),
         url('telefonicaweb-bold-webfont.woff') format('woff'),
         url('telefonicaweb-bold-webfont.ttf') format('truetype'),
         url('telefonicaweb-bold-webfont.svg#telefonicabold') format('svg');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'telefonicaextralight';
    src: url('telefonicaweb-extralight-webfont.eot');
    src: url('telefonicaweb-extralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('telefonicaweb-extralight-webfont.woff2') format('woff2'),
         url('telefonicaweb-extralight-webfont.woff') format('woff'),
         url('telefonicaweb-extralight-webfont.ttf') format('truetype'),
         url('telefonicaweb-extralight-webfont.svg#telefonicaextralight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'telefonicahand';
    src: url('telefonicaweb-hand-webfont.eot');
    src: url('telefonicaweb-hand-webfont.eot?#iefix') format('embedded-opentype'),
         url('telefonicaweb-hand-webfont.woff2') format('woff2'),
         url('telefonicaweb-hand-webfont.woff') format('woff'),
         url('telefonicaweb-hand-webfont.ttf') format('truetype'),
         url('telefonicaweb-hand-webfont.svg#telefonicahand') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'telefonicalight';
    src: url('telefonicaweb-light-webfont.eot');
    src: url('telefonicaweb-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('telefonicaweb-light-webfont.woff2') format('woff2'),
         url('telefonicaweb-light-webfont.woff') format('woff'),
         url('telefonicaweb-light-webfont.ttf') format('truetype'),
         url('telefonicaweb-light-webfont.svg#telefonicalight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'telefonicaregular';
    src: url('telefonicaweb-regular-webfont.eot');
    src: url('telefonicaweb-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('telefonicaweb-regular-webfont.woff2') format('woff2'),
         url('telefonicaweb-regular-webfont.woff') format('woff'),
         url('telefonicaweb-regular-webfont.ttf') format('truetype'),
         url('telefonicaweb-regular-webfont.svg#telefonicaregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'telefonicaregular_italic';
    src: url('telefonicaweb-regularitalic-webfont.eot');
    src: url('telefonicaweb-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('telefonicaweb-regularitalic-webfont.woff2') format('woff2'),
         url('telefonicaweb-regularitalic-webfont.woff') format('woff'),
         url('telefonicaweb-regularitalic-webfont.ttf') format('truetype'),
         url('telefonicaweb-regularitalic-webfont.svg#telefonicaregular_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}
